//////////////////// global-nav ////////////////////
.drawer {
  position: relative;
  @include min-screen($breakpoint_pc) {
    position: static;
    width: 100%;
  }
  // inputのcheckboxは非表示
  &__checkbox {
    display: none;
  }
  &__test {
    border: solid 1px #000;
  }
  // ハンバーガーアイコン
  &__icon {
    cursor: pointer;
    display: block;
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    width: 40px;
    padding: 1rem 1rem 1.1rem;
    background: $bg_gray;
    border: solid 1px #ccc;
    border-radius: 0.25rem;
    @include min-screen($breakpoint_pc) {
      display: none;
    }
    // ハンバーガーアイコンの中の線
    &-parts,
    &-parts:before,
    &-parts:after {
      background-color: #333;
      display: block;
      width: 20px;
      height: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &-parts:before,
    &-parts:after {
      content: " ";
    }
    &-parts:before {
      top: 0.5rem;
    }
    &-parts:after {
      top: -0.5rem;
    }
  }
  // ドロワーメニュー
  &__menu {
    position: fixed;
    top: 0;
    right: -100%;
    max-width: 100%;
    width: 75%;
    height: 100vh;
    padding: 2.8rem 1rem 1rem;
    background: $main_color;
    color: $bg_color;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 9000;
    @include min-screen($breakpoint_pc) {
      position: static;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 0.5rem;
    }
  }
  // ドロワーメニュー開いた時のメインエリアを暗く
  &__overlay {
    background: #fff;
    opacity: 0;
    pointer-events: none; //ポインタの動作全部無効化
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
  }
  // z-indexの指定（1がメインエリア）
  &__icon {
    z-index: 9999;
  }
  &__menu {
    z-index: 8000;
  }
  &__overlay {
    z-index: 2;
  }
  // チェックが入ったら（アイコンクリックしたら）
  &__checkbox:checked ~ {
    // ハンバーガーアイコン
    .drawer__icon {
      .drawer__icon-parts {
        background: transparent;
      }
      .drawer__icon-parts:before,
      .drawer__icon-parts:after {
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        background: $main_color;
        transform: translateX(-50%);
      }
      .drawer__icon-parts:before {
        transform: rotate(-45deg);
      }
      .drawer__icon-parts:after {
        transform: rotate(45deg);
      }
    }
    // メインエリアを暗くする
    .drawer__overlay {
      opacity: 0.3;
      pointer-events: auto;
    }
    // メニューをだす
    .drawer__menu {
      right: 0;
    }
  }
  // 動きをスムーズに
  &__icon-parts,
  &__icon-parts:after,
  &__icon-parts:before,
  &__overlay,
  &__menu {
    transition: all 0.4s;
  }
}
////////////////////
// global-nav
////////////////////
.global-nav {
  @include min-screen($breakpoint_pc) {
    display: flex;
    flex-wrap: nowrap;
  }
  & li {
    a {
      display: block;
      padding: 1rem;
      color: $bg_color;
      text-decoration: none;
      border-bottom: solid 1px #999;
      @include min-screen($breakpoint_pc) {
        padding: {
          right: 1.5rem;
          left: 1.5rem;
        }
        border-bottom: none;
        transition: 0.2s;
      }
      &:hover {
        @include min-screen($breakpoint_pc) {
          color: $sub_color;
          transform: rotate(-5deg);
        }
      }
    }
  }
}
.icon {
  & a::before {
    margin-right: 0.4rem;
  }
  &--home a::before {
    content: "\f015";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
  &--web a::before {
    content: "\f07b";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
  &--about a::before {
    content: "\f406";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
}
.sub-menu {
  @include min-screen($breakpoint_pc) {
    display: none;
  }
}
