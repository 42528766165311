.about {
  &-details {
    margin-top: 1rem;
    padding: 2rem 1rem;
    background: $main_color;
    & dl {
      padding: {
        top: 1rem;
        bottom: 1rem;
      }
      border-top: solid 1px #fff;
      &:last-child {
        border-bottom: solid 1px #fff;
      }
      & dt,
      & dd {
        color: #fff;
      }
      & dt {
        margin-bottom: 0.5rem;
        padding: {
          right: 0.5rem;
          left: 0.5rem;
        }
      }
      & dd {
        padding: {
          right: 1rem;
          left: 1rem;
        }
      }
    }
    &__map {
      display: inline-block;
      margin-left: 0.25rem;
      padding: 0.25rem 1rem;
      background: $key_color;
      color: #fff;
      text-decoration: none;
      border-radius: 8px;
    }
  }
}
