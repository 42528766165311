.contact {
  background: $bg_gray;
  &-form {
    width: 100%;
    margin: {
      top: 1rem;
      bottom: 1rem;
    }
    padding: 2rem 1rem;
    background: $bg_color;
    & dt {
      position: relative;
      margin-bottom: 0.5rem;
      font-weight: bold;
      & > span {
        position: absolute;
        top: -0.25rem;
        right: 0.5rem;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        background: $text_color;
        color: $bg_color;
      }
    }
    & dd {
      margin-bottom: 1rem;
      & input,
      & textarea {
        padding: 0.5rem;
        background: $bg_gray;
        border: none;
        &:focus {
          background: rgba($sub_color, 0.2);
          border: none;
        }
      }
      & input {
        width: 100%;
        height: 40px;
      }
      & select {
        width: 30%;
        height: 30px;
        margin-right: 0.25rem;
      }
      textarea {
        width: 100%;
        height: 150px;
      }
    }
  }
  & .agree {
    margin-bottom: 1rem;
    padding: {
      right: 1rem;
      left: 1rem;
    }
    & input[type="checkbox"] {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  & .submit {
    display: block;
    width: 200px;
    height: 40px;
    margin: {
      right: auto;
      left: auto;
    }
    background: $sub_color;
    color: $bg_color;
    border: none;
  }
}
