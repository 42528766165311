.recruit {
  background: $bg_color;
  &-details {
    margin: {
      top: 2rem;
      bottom: 2rem;
    }
    &__title {
      padding: 0.5rem;
      background: $bg_gray;
      font-weight: bold;
    }
    &__contents {
      padding: 1rem;
    }
  }
}
