.section {
  &-title {
    @include fontsize(32);
    font-weight: bold;
  }
  &-description {
    color: rgba($main_color, 0.6);
  }
}
.inner {
  padding: 3rem 1rem;
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin: {
      right: auto;
      left: auto;
    }
  }
}
.contents {
  padding: {
    top: 3rem;
    bottom: 3rem;
  }
  &-box {
    padding-top: 3rem;
    & p {
      margin-bottom: 2rem;
      line-height: 2;
    }
  }
}
.title {
  @include fontsize(20);
  font-weight: bold;
  text-align: center;
  &--common {
    padding: {
      top: 1rem;
      bottom: 1rem;
    }
    background: $key_color;
    color: $bg_color;
  }
}
.description {
  margin: {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  @include fontsize(22);
  font-weight: bold;
}
