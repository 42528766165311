.business {
  padding-bottom: 2rem;
  background: $bg_gray;
  &-list {
    &__item {
      margin-bottom: 1rem;
      padding: 1rem;
      background: #fff;
      border-radius: 10px;
    }
    &__title {
      margin-bottom: 2rem;
      padding: {
        top: 0.25rem;
        bottom: 0.25rem;
      }
      background: $main_color;
      color: #fff;
      @include fontsize(20);
      text-align: center;
    }
    & figure {
      width: 200px;
      margin: {
        right: auto;
        bottom: 1rem;
        left: auto;
      }
    }
    & h4 {
      margin-bottom: 0.25rem;
      color: $sub_color;
      font-weight: bold;
    }
    & p {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .cando {
    &__title {
      margin-bottom: 1rem;
      @include fontsize(24);
      font-weight: bold;
      text-align: center;
    }
    &-item {
      width: calc(100% - 2rem);
      margin: 0 auto;
      padding: 1rem;
      background: #fff;
      border-radius: 10px;
    }
    &-description {
      margin-bottom: 1rem;
      @include fontsize(22);
      text-align: center;
    }
    & p {
      margin-bottom: 2rem;
      & span {
        color: $sub_color;
      }
    }
    &-list {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: {
        top: 1rem;
        bottom: 1rem;
      }
      border-top: dotted 3px $sub_color;
      &:last-child {
        border-bottom: dotted 3px $sub_color;
      }
      & li {
        padding: {
          right: 0.5rem;
          left: 0.5rem;
        }
        &:first-child {
          width: 30%;
        }
        &:last-child {
          width: 70%;
        }
        & h3 {
          margin-bottom: 0.5rem;
          color: $sub_color;
          font-weight: bold;
        }
      }
    }
  }
}
