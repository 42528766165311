html {
  @include fontsize(14);
  @include min-screen($breakpoint_pc) {
    @include fontsize(16);
  }
}
body {
  position: relative;
  background: $bg_color;
  color: $main_color;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p",
    sans-serif;
  line-height: 1.5;
  letter-spacing: 0.04rem;
  counter-reset: post_rank;
}
a {
  color: $link_color;
}
.page-scroll {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  width: 36px;
  height: 36px;
  background: $key_color;
  border-radius: 50%;
  & a {
    display: block;
    width: 100%;
    height: 100%;
    color: $bg_color;
    text-align: center;
    border-radius: 50%;
    & i {
      line-height: 36px;
    }
  }
}
.fade {
  &--off {
    opacity: 0;
    transition: 1s;
    transform: translateY(-5rem);
  }
  &--on {
    opacity: 1;
    transform: translateY(0);
  }
}
.bg {
  &--gray {
    background: $bg_gray;
  }
}
.btn {
  width: 200px;
  margin: {
    right: auto;
    left: auto;
  }
  background: $bg_color;
  text-align: center;
  border: solid 1px $main_color;
  border-radius: 0.25rem;
  @include min-screen($breakpoint_pc) {
    width: 350px;
  }
  &--more {
    a {
      &::before {
        content: "+";
        color: $key_color;
        vertical-align: 1px;
      }
    }
  }
  & a {
    display: block;
    padding: 0.25rem 1.5rem;
    color: $main_color;
    text-decoration: none;
    @include min-screen($breakpoint_pc) {
      padding: 1rem 2rem;
    }
    &:hover {
      transition: 0.3s;
      @include min-screen($breakpoint_pc) {
        background: rgba($key_color, 0.2);
      }
    }
  }
}
