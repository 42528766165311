.footer {
  padding: {
    top: 1rem;
    bottom: 1rem;
  }
  &__logo {
    width: 100px;
    margin: {
      right: auto;
      bottom: 0.5rem;
      left: auto;
    }
  }
  &-info {
    margin-bottom: 1rem;
    padding: {
      right: 1rem;
      left: 1rem;
    }
    @include fontsize(12);
    @include min-screen($breakpoint_pc) {
      width: $wrap_pc;
      margin: {
        right: auto;
        left: auto;
      }
    }
    &__body:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &__copyright {
    text-align: center;
    & small {
      @include fontsize(12);
    }
    & a {
      color: $main_color;
      text-decoration: none;
    }
  }
}
//////////////////// footer-nav ////////////////////
.footer-nav {
  margin-bottom: 2rem;
  padding: {
    right: 1rem;
    left: 1rem;
  }
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin: {
      right: auto;
      bottom: 2rem;
      left: auto;
    }
  }
  & ul {
    width: 90%;
    margin: {
      right: auto;
      bottom: 2rem;
      left: auto;
    }
  }
  & li {
    border-top: solid 1px $main_color;
    &:not(:last-child) {
      margin-right: 0.5rem;
      @include min-screen($breakpoint_pc) {
        margin-right: 1rem;
      }
    }
    &:last-child {
      border-bottom: solid 1px;
    }
    & a {
      display: block;
      padding: 1rem;
      color: $main_color;
      text-decoration: none;
      @include min-screen($breakpoint_pc) {
        transition: 0.2s;
      }
      &:hover {
        @include min-screen($breakpoint_pc) {
          color: $sub_color;
        }
      }
    }
  }
}
