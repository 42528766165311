$breakpoint_pc: 1112px;
$breakpoint_tb: 768px;
$breakpoint_sp: 640px;
$breakpoint_ip5: 320px;
$wrap_pc: 1200px;
$wrap_max: 100%;
$key_color: #e75b60;
$sub_color: #4990ff;
$main_color: #333;
$link_color: #1166c9;
$bg_color: #fff;
$bg_gray: #f2f2f2;
$bg_postColor: #f2f2f2;
$text_color: #191919;
// media queries
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}
@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}
// clear fix
@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
// link color
@mixin linkcolor($color) {
  display: inline-block;
  color: $color;
  text-decoration: underline;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: lighten($color, 15%);
    text-decoration: underline;
  }
}
// font-size
@mixin fontsize($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}
// font-title
@mixin fonttitle() {
  // font-family: 'Dancing Script', cursive;
  font-family: "Cinzel", serif;
}
