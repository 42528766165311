.key-visual {
  position: relative;
  padding: {
    top: 10rem;
    bottom: 10rem;
  }
  background: {
    image: url(./images/key_visual.jpg);
  }
  @include min-screen($breakpoint_pc) {
    padding: {
      top: 20rem;
      bottom: 20rem;
    }
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    color: $bg_color;
    @include fontsize(30);
    font-family: "Cormorant Garamond", serif;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    @include min-screen($breakpoint_pc) {
      @include fontsize(60);
    }
  }
}
.sub-catch {
  padding: {
    top: 1rem;
    bottom: 1rem;
  }
  @include min-screen($breakpoint_pc) {
    padding: {
      top: 2rem;
      bottom: 2rem;
    }
  }

  & p {
    @include min-screen($breakpoint_pc) {
      width: $wrap_pc;
      margin: {
        right: auto;
        left: auto;
      }
    }
  }
}
.news {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  @include min-screen($breakpoint_pc) {
    justify-content: left;
  }
  &-list {
    width: calc(100% / 3 - 0.5rem);
    @include min-screen($breakpoint_pc) {
      width: calc(100% / 3 - 2rem);
      margin-bottom: 2rem;
      &:not(:nth-of-type(3n)) {
        margin-right: 1rem;
      }
    }
    &__eyecatch {
      margin-bottom: 0.5rem;
    }
    &__details {
      @include fontsize(14);
      @include min-screen($breakpoint_pc) {
        @include fontsize(16);
      }
      & > a {
        text-decoration: none;
      }
      & span {
        display: block;
      }
    }
  }
}
.messeage {
  &__description {
    padding: 2rem 1rem;
    background: $main_color;
    color: $bg_color;
    @include fontsize(20);
    @include min-screen($breakpoint_pc) {
      padding: {
        right: 2rem;
        left: 2rem;
      }
    }
  }
  &__text {
    padding: 1.5rem;
    background: {
      image: url(images/img_messeage.jpg);
      color: rgba($bg_color, 0.7);
      repeat: no-repeat;
      blend-mode: lighten;
      position: center;
      size: cover;
    }
    @include min-screen($breakpoint_pc) {
      min-height: 450px;
    }
    & p {
      margin-bottom: 2rem;
      text-shadow: 1px 1px 1px $bg_color;
      line-height: 2;
      @include min-screen($breakpoint_pc) {
        width: 80%;
        margin: {
          right: auto;
          left: auto;
        }
        @include fontsize(20);
      }
    }
  }
}
