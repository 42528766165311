.news-archive {
  margin-top: 2rem;
  @include min-screen($breakpoint_pc) {
    display: flex;
    flex-wrap: wrap;
  }
  &-list {
    margin-bottom: 1rem;
    box-shadow: 1px 1px #efefef, -1px -1px #efefef;
    @include min-screen($breakpoint_pc) {
      width: calc(100% / 3 - 3rem);
      margin: {
        right: 1rem;
        bottom: 2rem;
        left: 1rem;
      }
    }
    & a {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem 0.5rem;
      color: $text_color;
      text-decoration: none;
      @include min-screen($breakpoint_pc) {
        display: block;
      }
    }
    &__eyecatch {
      position: relative;
      width: 30%;
      margin-right: 2%;
      @include min-screen($breakpoint_pc) {
        width: 100%;
      }
    }
    &__category {
      position: absolute;
      top: -1rem;
      left: -0.5rem;
      display: block;
      padding: 0.25rem 0.5rem;
      background: $key_color;
      color: #fff;
      @include fontsize(12);
    }
    &__details {
      width: 68%;
      @include min-screen($breakpoint_pc) {
        width: 100%;
      }
      & span {
        display: block;
        margin-bottom: 0.25rem;
        @include fontsize(12);
        @include min-screen($breakpoint_pc) {
          @include fontsize(16);
        }
      }
    }
  }
}
.pagenation {
  @include min-screen($breakpoint_pc) {
    text-align: center;
  }
  & span,
  & a {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border: solid 1px $text_color;
  }
  & span {
    background: $text_color;
    color: #fff;
  }
  & a {
  }
}
